import React from 'react'
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import ContactBar from '../../components/ContactBar';
import SingleImage from '../../library/SingleImage';
import Layout from '../../components/Layout';

const AkupunkturPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Akupunktur – Stagnationen lösen, Fluss der Energie wieder herstellen</title>
        <meta name="description" content="Die Akupunktur kann Organe stärken und von krankmachenden Faktoren befreien." />
        <meta property="og:title" content="Akupunktur – Stagnationen lösen, Fluss der Energie wieder herstellen" />
        <meta property="og:description" content="Die Akupunktur kann Organe stärken und von krankmachenden Faktoren befreien." />
        
        {/* <meta property="og:image" content="https://www.paolanakahara.ch/wp-content/uploads/wide_Akupunktur_02.png" /> */}
        {/* <meta property="og:image:secure_url" content="https://www.paolanakahara.ch/wp-content/uploads/wide_Akupunktur_02.png" /> */}
        {/* <meta property="og:image:width" content="957" /> */}
        {/* <meta property="og:image:height" content="330" /> */}
        {/* <meta property="og:image:alt" content="Akupunktur am Rücken" /> */}
        
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Akupunktur</h1>
        <SingleImage image={props.data.akupunktur.fluid} />
        <p>Als Akupunktur bezeichnet man das Stechen von dünnen Nadeln in sogenannte Akupunkturpunkte.</p>
        <p>Auf unserem Körper befinden sich verschiedene Meridiane (Leitbahnen) zu denen jeweils eine gewisse Anzahl von Akupunkturpunkten gehören. Die Meridiane sind bestimmten Organen und Körperbereichen zugeordnet, welche sie versorgen und unterstützen. Durch das Stechen und sanfte Manipulieren dieser Punkte ist es möglich Stagnationen zu lösen und den freien Fluss der Energie im Körper wiederherzustellen. Die Akupunktur kann aber auch Organe stärken und von krankmachenden Faktoren befreien.</p>
        <p>Akupunktur ist geeignet für Schmerzzustände, Verdauungssbeschwerden, Schlafstörungen, gynäkologische Beschwerden, Schwangerschaft, Hautprobleme...</p>
      </div>
    </div>
  </Layout>
)

export default AkupunkturPage;

export const query = graphql`
  query AkupunkturImageQuery {
    akupunktur: imageSharp(fluid: { originalName: {regex: "/akupunktur/" }}) {
      fluid(maxHeight: 580, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;



